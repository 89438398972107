import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Container, Form, FormControl, InputGroup, Modal, ProgressBar, Row, Stack, Table } from 'react-bootstrap';
import { faCheck, faPencil, faTrashCan, faPlus, faClone, faAnglesDown, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { docUrl, modelsUrl, usersUrl } from '../../utils/api';
import { heightAdjust } from '../../utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Autosuggest from 'react-autosuggest';
import './model.css';

async function getModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getCustomers(token) {

    return fetch(`${usersUrl}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json())
        .catch(e=>{
            console.log(e);
            return []
        })
}

async function createModel(token, data) {

    return fetch(`${modelsUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateModel(token, modelId, data) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function delModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getModelDataList(token, modelId, limit, offset, version = null) {

    return fetch(`${modelsUrl}/${modelId}/data?limit=${limit || 0}&offset=${offset || 0}${version ? `?version=${version}` : ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getVariations(token, modelId, modelDataId) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}/generate`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function saveModelDataVariations(token, modelId, modelDataId, data) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function getModels(token) {

    return fetch(`${modelsUrl}?limit=1000&offset=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function delModelData(token, modelId, modelDataId) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function uploadFileCsv(token, modelId, fileFormData, replaceData = false) {

    return fetch(`${modelsUrl}/${modelId}/data/import${replaceData ? `?replace=1` : ''}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: fileFormData,
    })
        .then(data => data.json());
}

async function prepareExportFileCsv(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}/data/export`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function generateTrainingFile(token, modelId) {
    // return {
    //     filename: "1.json",
    // };

    return fetch(`${modelsUrl}/${modelId}/generate`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function uploadTrainingFile(token, modelId) {
    // return {
    //     ai_file_id: "1",
    // };

    return fetch(`${modelsUrl}/${modelId}/upload`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function startTrainingModel(token, modelId) {
    // return {
    //     ai_fine_tune_id: "1",
    //     ai_fine_tuned_model: "1m",
    //     ai_fine_tune_status: "1s",
    // };

    return fetch(`${modelsUrl}/${modelId}/start`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function trainModel(token, modelId) {
    return fetch(`${modelsUrl}/${modelId}/train`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function checkTrainingModel(token, modelId) {
    // return {
    //     ai_fine_tune_status: Math.random() > 0.25 ? 'pending' : 'succeeded',
    // };

    return fetch(`${modelsUrl}/${modelId}/check`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function resetTrainingModel(token, modelId) {
    // return {
    //     reset: 1,
    // };

    return fetch(`${modelsUrl}/${modelId}/reset`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

export default function Model(props) {
    const history = useHistory();
    const location = useLocation();
    let { modelId: id } = useParams();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [modelVersions, setModelVersions] = useState([]);
    // const [modelVersion, setModelVersion] = useState();
    const [modelId, setModelId] = useState(id);
    const [modelGeneral, setModelGeneral] = useState();
    const [modelExternal, setModelExternal] = useState();
    const [modelClass, setModelClass] = useState();
    const [modelTitle, setModelTitle] = useState('');
    const [modelFormatPhrase, setModelFormatPhrase] = useState('');
    const [modelTask, setModelTask] = useState('');
    const [modelTaskSuffix, setModelTaskSuffix] = useState('');
    const [modelClassification, setModelClassification] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [modelUsers, setModelUsers] = useState([]);
    const [modelPrompt, setModelPrompt] = useState([]);
    const [modelCompletion, setModelCompletion] = useState([]);
    const [modelPrefix, setModelPrefix] = useState('');
    const [modelBase, setModelBase] = useState('davinci');
    const [modelInitialPrompt, setInitialModelPrompt] = useState([]);
    const [modelInitialCompletion, setInitialModelCompletion] = useState([]);
    const [selectedFileCsv, setSelectedFileCsv] = useState(null);
    const [modelDataList, setModelDataList] = useState([]);
    const [modelsCollapse, setModelsCollapse] = useState({});
    const { settings, setSettings } = useSettings();
    // const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(0);
    const [counts, setCounts] = useState({});
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState(null);

    const [aiTrainingFileId, setAiTrainingFileId] = useState('');
    const [aiTrainingModelId, setAiTrainingModelId] = useState('');
    const [aiTrainingModelName, setAiTrainingModelName] = useState(null);
    const [aiTrainingModelStatus, setAiTrainingModelStatus] = useState('');

    const [progress, setProgress] = useState(false);
    const [progressStatus, setProgressStatus] = useState('');
    const [progressInterval, setProgressInterval] = useState();

    const [replaceData, setReplaceData] = useState(false);

    const [show, setShow] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [modelDelete, setModelDelete] = useState('');

    const [variations, setVariations] = useState([]);
    const [showVariations, setShowVariations] = useState(false);
    const [models, setModels] = useState([]);
    const [variationModelId, setVariationModelId] = useState();

    const [modelModified, setModelModified] = useState(false);

    const [apiKey, setApiKey] = useState();

    const [retrain, setRetrain] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelClose = () => setShowDel(false);
    const handleDelShow = () => setShowDel(true);
    const handleVariationsClose = () => setShowVariations(false);
    const handleVariationsShow = () => setShowVariations(true);

    const query = new URLSearchParams(location.search);
    const pathId = query.get('pathId');
    const modelAddr = query.get('m');

    useEffect(() => {
        if (id) {
            setModelId(id);
            if (query.get('action') == 'createModelData') {
                history.push(`/models/model/${modelId}/data`);
            }
            // setModelVersion(query.get('version') || null);
            loadModel();
            loadModelDataList(0);
        } else {
            if (query.get('type') == 'answer') {
                setModelClass("0");
            }
            if (query.get('type') == 'classification') {
                setModelClass("1");
            }
        }
        loadCustomers();
        loadModels();

        return () => {
            setModelVersions([]);
            // setModelVersion(null);
            setModelGeneral();
            setModelExternal();
            setModelClass();
            setModelTitle('');
            setModelFormatPhrase('');
            setModelTask('');
            setModelTaskSuffix('');
            setModelClassification([]);
            setModelPrompt([]);
            setModelCompletion([]);
            setModelPrefix('');
            setModelBase('');
            setInitialModelPrompt([]);
            setInitialModelCompletion([]);
            setModelDataList([]);
            setCustomers([]);
        }
    }, [token]);

    useEffect(() => {
        // console.log(1, aiTrainingModelId, aiTrainingModelStatus, window.progressInterval);
        if (aiTrainingModelId && aiTrainingModelStatus != 'succeeded' && !window.progressInterval) {
            const refreshStatus = async () => {
                await onCheckTraining(false);
                // console.log(2, aiTrainingModelStatus, aiTrainingModelId);
                if (aiTrainingModelStatus == 'succeeded') {
                    clearInterval(window.progressInterval);
                    window.progressInterval = null;
                }
            };
            refreshStatus();
            window.progressInterval = setInterval(refreshStatus, 30000);
        }

        return () => {
            clearInterval(window.progressInterval);
            window.progressInterval = null;
        }
    }, [aiTrainingModelId, aiTrainingModelStatus]);

    useEffect(() => {
        setApiKey(user.api_key);

        return () => setApiKey();
    }, [user, token]);

    const loadModel = () => {
        getModel(token, modelId).then(r => {
            if (!r.model || !r.model.id) {
                console.log(r);
                setError(r);
            }
            setModelVersions(r.model.versions || []);
            // setModelVersion(r.model.versions[0] ? r.model.versions[0].id : '');
            setModelGeneral(r.model.general);
            setModelExternal(r.model.external);
            setModelClass(r.model.class);
            setModelTitle(r.model.title);
            setModelFormatPhrase(r.model.format_phrase);
            setModelTask(r.model.task);
            if (r.model.task_suffix) {
                setModelTaskSuffix(r.model.task_suffix);
            }
            setModelClassification(JSON.parse(r.model.classification ? r.model.classification : '[]'));
            setModelUsers(r.model.users ? r.model.users : []);
            setModelPrompt(JSON.parse(r.model.prompt || '[]'));
            setModelCompletion(JSON.parse(r.model.completion || '[]'));
            setInitialModelPrompt(JSON.parse(r.model.prompt || '[]'));
            setInitialModelCompletion(JSON.parse(r.model.completion || '[]'));
            setModelPrefix(r.model.prefix);
            setAiTrainingFileId(r.model.ai_file_id || '');
            setAiTrainingModelId(r.model.ai_fine_tune_id || '');
            setAiTrainingModelName(r.model.ai_fine_tuned_model || null);
            setAiTrainingModelStatus(r.model.ai_fine_tune_status || '');

            setModelModified(false);
            const modelForm = document.getElementById("ModelForm");
            modelForm.addEventListener("input", function () {
                if (!modelModified) {
                    setModelModified(true);
                }
            });
        });
    };

    const loadCustomers = () => {
        // setError();
        getCustomers(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            }
            setCustomers(r.list?.length ? r.list.map(customer => customer) : []);
        });
    }

    const loadModelDataList = (offset, limit = null) => {
        // getModelDataList(token, modelId, limit || settings.items, offset, modelVersion).then(r => {
        getModelDataList(token, modelId, limit || settings.items, offset).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setTotal(r.total || modelDataList.length);
            setModelDataList(offset ? modelDataList.concat(r.list || []) : (r.list || []));
            setPending(r.pending || 0);
            setCounts(r.counts);
        });
    };

    const getMoreItems = () => {
        loadModelDataList(modelDataList.length);
    };

    const onSubmit = async (redirect, createNew = false) => {
        setError();

        if (modelId &&
            (JSON.stringify(modelInitialPrompt) != JSON.stringify(modelPrompt) ||
                JSON.stringify(modelInitialCompletion) != JSON.stringify(modelCompletion))) {
            alert('You have changed the structure. And you have to retrain the model before using it.');
        }

        const data = {
            title: modelTitle,
            format_phrase: modelFormatPhrase,
            class: modelClass,
            task: modelTask,
            task_suffix: modelTaskSuffix,
            classification: JSON.stringify(modelClassification),
            users: modelUsers,
            prompt: JSON.stringify(modelPrompt),
            completion: JSON.stringify(modelCompletion),
            prefix: modelPrefix,
            user_id: user.id,
            base: modelBase,
            variation_model_id: variationModelId,
        };
        if (modelId) {
            await updateModel(token, modelId, data);
            loadModel();
        } else {
            data.general = modelGeneral;
            data.external = modelExternal;
            await createModel(token, data).then(r => {
                if (!r.modelId) {
                    console.log(r);
                    setError(r);
                }
                setModelId(r.modelId);
                if (redirect) {
                    if (pathId) {
                        return history.push(`/paths/path/${pathId}?modelId=${r.modelId}${modelAddr ? `&m=${modelAddr.map(a => `[${a}]`).join('')}` : ''}`);
                    }
                }
                return history.push(`/models/model/${r.modelId}`);
            });
        }

        if (redirect) {
            if (createNew) {
                return history.push(`/models?action=createModel`);
            } else {
                return history.push(`/models`);
            }
        }
    };

    const removeModel = (modelId) => {
        setError();
        delModel(token, modelId).then(r => {
            console.log(r);
            history.push(`/models`);
        });
    };

    const removeModelData = (modelDataId) => {
        setError();
        if (window.confirm("Do you really want to delete this data?")) {
            delModelData(token, modelId, modelDataId).then(r => {
                console.log(r);
                setModelDataList(modelDataList.filter(item => item.id != modelDataId));
            });
        }
    };

    const generateVariations = (modelDataId, e) => {
        e.target.disabled = true;
        setError();
        getVariations(token, modelId, modelDataId).then(r => {
            if (!r.variations || !r.variations.length) {
                console.log(r);
                setError(r);
            }
            setVariations(r.variations.length ? r.variations : []);
            handleVariationsShow();
            e.target.disabled = false;
        });
    };

    const saveVariations = async (modelDataId) => {
        setError();
        const data = {
            variations,
        };
        await saveModelDataVariations(token, modelId, modelDataId, data).then(r => {
            if (!r || !r.modelDataIds) {
                console.log(r);
                setError(r);
            } else {
                loadModelDataList(0);
                handleVariationsClose();
                setTimeout(document.getElementById(modelDataId).scrollIntoView(true), 500);
            }
        });

    };

    const loadModels = () => {
        // setError();
        getModels(token).then(r => {
            if (!r.list || !r.list.length) {
                console.log(r);
                setError(r);
            } else {
                setModels(r.list.length ? r.list.map(model => model) : []);
            }
        });
    };

    const onUploadFileCsv = async (e) => {
        setError();

        const fileInput = document.getElementById('fileImport');
        const formData = new FormData();

        e.target.disabled = true;

        formData.append("importFileCsv", selectedFileCsv, selectedFileCsv.name);

        await uploadFileCsv(token, modelId, formData, replaceData)
            .then(r => {
                // console.log(r);
                if (r && r.imported) {
                    fileInput.value = null;
                    loadModelDataList(0);
                }
            });
    };

    const onDownloadFileCsv = () => {
        setError();
        prepareExportFileCsv(token, modelId).then(r => {
            console.log(r);
            if (r && r.export) {
                const a = document.createElement('a');
                a.target = "_blank"
                a.href = `${modelsUrl}/${modelId}/data/download/${r.export}`;
                a.download = r.export;
                a.click();
            }
        })
    };

    const onApplyTraining = async (resetError = true) => {
        if (resetError) {
            setError();
        }
        setProgressStatus('generating');
        setProgress(10);
        await generateTrainingFile(token, modelId).then(async r => {
            console.log('generateTrainingFile', r);
            if (r && r.filename) {
                setProgressStatus('uploading');
                setProgress(20);
                await uploadTrainingFile(token, modelId).then(r => {
                    console.log('uploadTrainingFile', r);
                    if (r && r.ai_file_id) {
                        setAiTrainingFileId(r.ai_file_id);
                        setProgressStatus('uploaded');
                        setProgress(30);
                    }
                });
            }
        });
    };

    const onStartTraining = async (resetError = true) => {
        if (resetError) {
            setError();
        }
        setProgressStatus('start training');
        setProgress(40);
        await startTrainingModel(token, modelId).then(r => {
            console.log('startTrainingModel', r);
            if (r && r.ai_fine_tune_id) {
                setAiTrainingModelId(r.ai_fine_tune_id);
                setAiTrainingModelName(r.ai_fine_tuned_model);
                setAiTrainingModelStatus(r.ai_fine_tune_status);
                setProgressStatus(`training: ${r.ai_fine_tune_status}`);
                setProgress(50);
            }
        });
    };

    const onTrain = async (resetError = true) => {
        if (resetError) {
            setError();
        }
        setProgressStatus('generating');
        setProgress(10);
        await trainModel(token, modelId).then(async r => {
            if (r && r.ai_fine_tune_id) {
                setAiTrainingModelId(r.ai_fine_tune_id);
                setAiTrainingModelName(r.ai_fine_tuned_model);
                setAiTrainingModelStatus(r.ai_fine_tune_status);
                setProgressStatus(`training: ${r.ai_fine_tune_status}`);
                setProgress(50);
            }
        });
    };

    const onCheckTraining = async (resetError = true) => {
        if (resetError) {
            setError();
        }

        await checkTrainingModel(token, modelId).then(r => {
            console.log('checkTrainingModel', r);
            if (r && r.ai_fine_tune_status) {
                setAiTrainingModelStatus(r.ai_fine_tune_status);
                setProgressStatus(`training: ${r.ai_fine_tune_status}`);
                setProgress(r.ai_fine_tune_status == 'succeeded' ? 100 : (!progress && r.ai_fine_tune_status != 'succeeded' ? 60 : progress) + (100 - progress) * 0.2);
                if (r.ai_fine_tune_status == 'succeeded') {
                    clearInterval(window.progressInterval);
                    window.progressInterval = null;
                }
            }
        });
    };

    const onResetTraining = async (resetError = true) => {
        if (resetError) {
            setError();
        }
        if (window.confirm('Are you sure to recreate the training model?')) {
            return await resetTrainingModel(token, modelId).then(r => {
                console.log('resetTrainingModel', r);
                if (r && r.reset) {
                    setAiTrainingFileId('');
                    setAiTrainingModelId('');
                    setAiTrainingModelName(null);
                    setAiTrainingModelStatus('');
                }
            });
        }
    };

    const modelPageTitle = modelId ? `Model "${modelTitle}"` : `Add New Model ${modelTitle ? `"${modelTitle}"` : ''}`;

    const getCustomerById = customerId => customers.filter(customer => customer.id == customerId)[0];

    const renderCustomer = customer => customer ? `${customer.first_name || ''} ${customer.last_name || ''} <${customer.email}>` : null;
    // const renderCustomer = customer => customer;

    const renderCustomerTag = (props) => {
        let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
        return (
            <span key={key} {...other}>
                {renderCustomer(customers.filter(customer => customer.id == tag)[0])}
                {!disabled &&
                    <a className={classNameRemove} onClick={(e) => onRemove(key)} />
                }
            </span>
        )
    };

    const autocompleteRenderInput = ({ addTag, ...props }) => {
        const handleOnChange = (e, { newValue, method }) => {
            if (method === 'enter') {
                e.preventDefault();
            } else {
                props.onChange(e);
            }
        }

        const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
        const inputLength = inputValue.length;

        const suggestions = customers.filter(customer => !modelUsers.includes(customer.id) &&
            (
                `${customer.first_name}`.toLowerCase().slice(0, inputLength) === inputValue
                ||
                `${customer.last_name}`.toLowerCase().slice(0, inputLength) === inputValue
                ||
                `${customer.email}`.toLowerCase().slice(0, inputLength) === inputValue
            )
        );

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={suggestions}
                shouldRenderSuggestions={value => renderCustomer(value)}
                getSuggestionValue={suggestion => suggestion.id}
                renderSuggestion={suggestion => <span>{renderCustomer(suggestion)}</span>}
                inputProps={{ ...props, onChange: handleOnChange }}
                onSuggestionSelected={(e, { suggestion }) => {
                    addTag(suggestion.id);
                }}
                onSuggestionsClearRequested={() => { }}
                onSuggestionsFetchRequested={() => { }}
            />
        )
    };

    const validData = async () => {
        // setValidationError(null);

        console.log('There are NO empty fields');
        return true;
    };

    // const defaultTaskSuffix = 'Classify if the prompt is one of the following:';

    return (
        <>
            {
                pathId ? (
                    <Breadcrumb>
                        <Breadcrumb.Item href="#/paths">Paths</Breadcrumb.Item>
                        <Breadcrumb.Item href={`#/paths/path/${pathId}`}>Path</Breadcrumb.Item>
                        <Breadcrumb.Item active>{modelPageTitle}</Breadcrumb.Item>
                        {modelGeneral || modelExternal || modelVersions.filter(version => version.ai_fine_tune_status == 'succeeded').length ? (
                            <Breadcrumb.Item href={`#/models/model/${modelId}/sandbox?pathId=${pathId}`}>Sandbox</Breadcrumb.Item>
                        ) : ''}
                    </Breadcrumb>
                ) : (
                    <Breadcrumb>
                        {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                            e.preventDefault();
                            document.getElementById('linkUser').click();
                        }}>Home</Breadcrumb.Item> */}
                        <Breadcrumb.Item href="#/models">Models</Breadcrumb.Item>
                        <Breadcrumb.Item active>{modelPageTitle}</Breadcrumb.Item>
                        {modelGeneral || modelExternal || modelVersions.filter(version => version.ai_fine_tune_status == 'succeeded').length ? (
                            <Breadcrumb.Item href={`#/models/model/${modelId}/sandbox`}>Sandbox</Breadcrumb.Item>
                        ) : ''}
                        {/* <a
                            href="#/login"
                            className="ms-auto"
                            onClick={e => {
                                setToken({ token: null });
                                setUser({});
                                const linkLogout = document.getElementById('linkLogout');
                                if (linkLogout) linkLogout.click();
                            }}
                        >Log out</a> */}
                    </Breadcrumb >
                )
            }
            {
                error && (
                    <Alert variant="danger" className="row">
                        {`${error.error &&
                            (
                                (error.error.msg || '') + ' ' +
                                (error.error.code ? ('(' + error.error.code + ')') : '')
                            )}`}
                    </Alert>
                )
            }

            <h2 className="my-2 text-center">
                {modelPageTitle}
            </h2>
            {modelId ?
                <>
                    <h4 className="mt-4">API Handler for using the model:</h4>
                    {
                        modelExternal ? (
                            <h6 className="my-2">
                                <a href={`${docUrl}#model`}>
                                    {`POST https://api.strietzel.ai/v1/models/${modelId}/generate_subtitles?api_key=${apiKey}&from=en-US&to=ja`}
                                </a>
                            </h6>
                        ) : (
                            <h6 className="my-2">
                                {`POST https://api.strietzel.ai/v1/${modelId}/q?api_key=${apiKey}`}
                            </h6>
                        )
                    }
                </> : ''}
            <Row>
                {
                    !modelExternal ?
                        <>
                            <Col>
                                <Stack direction='horizontal'>
                                    <Form.Label htmlFor="modelClass" className="my-1 ms-0 me-4">Model&nbsp;Type:</Form.Label>
                                    <Form.Select
                                        id="modelClass"
                                        aria-label="Select Model Type"
                                        value={modelClass}
                                        onChange={e => setModelClass(e.target.value)}
                                        className="ms-0 me-4"
                                        size="sm"
                                    >
                                        <option disabled selected>Select Model Type</option>
                                        <option value="0">Answer Model</option>
                                        <option value="1">Classification Model</option>
                                    </Form.Select>
                                </Stack>
                            </Col>
                            <Col>
                                {/* {modelClass == '0' ?
                                    <Stack direction='horizontal'>
                                        <Form.Label htmlFor="variationModel" className="my-1 ms-0 me-4">Variation&nbsp;Model:</Form.Label>
                                        <Form.Select
                                            id="variationModel"
                                            aria-label="Select Variation Model"
                                            value={variationModelId}
                                            onChange={e => setVariationModelId(e.target.value)}
                                            className="ms-0 me-4"
                                            size="sm"
                                        >
                                            <option disabled selected>Select Variation Model</option>
                                            {models ? models.map(model => (
                                                <option value={model.id}>{model.title}</option>
                                            )) : ''}
                                        </Form.Select>
                                    </Stack>
                                    : ''} */}
                            </Col>
                        </>
                        : ''
                }
                {
                    modelId ? '' : (
                        <>
                            {!modelExternal ?
                                <Col>
                                    <Form.Label htmlFor="modelGeneral" className="my-1 ms-0 me-4">Fine Tuned Model:</Form.Label>
                                    <Form.Check
                                        inline
                                        id="modelGeneral"
                                        type="switch"
                                        className="my-1 ms-0 me-0"
                                        onChange={e => setModelGeneral(!e.target.checked)}
                                        checked={!modelGeneral}
                                        style={{ fontSize: '1.25rem' }}
                                    />
                                    <Form.Label htmlFor="modelGeneral" className="my-1 ms-0 me-4">{modelGeneral ? 'Off' : 'On'}</Form.Label>
                                </Col>
                                : ''}
                            <Col>
                                <Form.Check
                                    inline
                                    id="modelExternal"
                                    type="switch"
                                    className="my-1 ms-0 me-0"
                                    onChange={e => setModelExternal(e.target.checked)}
                                    checked={modelExternal}
                                    style={{ fontSize: '1.25rem' }}
                                />
                                <Form.Label htmlFor="modelExternal" className="my-1 ms-0 me-4">{modelExternal ? 'External' : 'Internal'}</Form.Label>
                            </Col>
                        </>
                    )
                }
            </Row>
            {
                modelGeneral || modelExternal ? (<>
                    <h4 className="mt-4">{modelGeneral ? 'General' : (modelExternal ? 'External' : '')} Model</h4>
                </>) : (
                    <>
                        <h4 className="mt-4">Train AI Model</h4>
                        <Stack direction="horizontal">
                            {modelDataList.length && (pending || (!pending && aiTrainingFileId && aiTrainingModelId && aiTrainingModelId && aiTrainingModelStatus)) ? (
                                <Button
                                    className="my-1 ms-0 me-auto"
                                    id="buttonTraining"
                                    size="sm"
                                    disabled={modelModified}
                                    onClick={async e => {
                                        e.target.disabled = true;

                                        if (modelDataList.length) {
                                            if (modelDataList.filter(data => {
                                                let empty = 0;
                                                let prompt = JSON.parse(data.prompt || []);
                                                if (!prompt || !prompt.length) {
                                                    prompt = [];
                                                }
                                                let completion = JSON.parse(data.completion || []);
                                                if (!completion || !completion.length) {
                                                    completion = [];
                                                }
                                                modelPrompt.map((p, i) => {
                                                    if (!prompt[i] || !prompt[i].length) {
                                                        ++empty;
                                                    }
                                                });
                                                modelCompletion.map((c, i) => {
                                                    if (!completion[i] || !completion[i].length) {
                                                        ++empty;
                                                    }
                                                });
                                                return empty;
                                            }).length) {
                                                setValidationError('Please fill all the prompt and completion fields in the model data');
                                                e.target.disabled = false;
                                                return false;
                                            } else {
                                                setValidationError(null);
                                            }
                                        }

                                        if (!pending && aiTrainingModelStatus == 'succeeded') {
                                            handleShow();
                                            // alert("You must add new training data in order to retrain this model");
                                            e.target.disabled = false;

                                            return false;
                                        }

                                        setError();

                                        if (
                                            (!aiTrainingFileId && modelVersions.filter(version => version.ai_fine_tune_status == 'succeeded').length) ||
                                            (aiTrainingFileId && aiTrainingModelStatus == 'succeeded')
                                        ) {
                                            await onResetTraining(false);
                                            // await onApplyTraining(false);
                                            // await onStartTraining(false);
                                            await onTrain(false);
                                        } else if (!aiTrainingModelId) {
                                            // await onApplyTraining(false);
                                            // await onStartTraining(false);
                                            await onTrain(false);
                                        }
                                        await onCheckTraining(false);
                                        loadModel();
                                        loadModelDataList(0);
                                        e.target.disabled = false;
                                    }}
                                >
                                    {
                                        (aiTrainingFileId && aiTrainingModelId && aiTrainingModelId && aiTrainingModelStatus != 'succeeded') ?
                                            `Check the training job status ${aiTrainingModelStatus ? `: ${aiTrainingModelStatus}` : ''}` :
                                            '(Re)Start Model Training'
                                    }
                                </Button>
                            ) : (
                                <Button
                                    className="my-1 ms-0 me-auto"
                                    size="sm"
                                    onClick={async e => {
                                        e.target.disabled = true;
                                        e.preventDefault();
                                        e.target.disabled = false;
                                    }}
                                    disabled
                                >
                                    {
                                        (aiTrainingFileId && aiTrainingModelId && aiTrainingModelId && aiTrainingModelStatus != 'succeeded') ?
                                            `Check the training job status ${aiTrainingModelStatus ? `: ${aiTrainingModelStatus}` : ''}` :
                                            '(Re)Start Model Training'
                                    }
                                </Button>
                            )}

                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>(Re)Start Model Training</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>You must add new training data in order to retrain this model</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        OK
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* <Form.Select
                                aria-label="Base model type"
                                value={modelBase}
                                onChange={e => setModelBase(e.target.value)}
                                className="w-25 ms-auto me-0"
                                size="sm"
                            >
                                <option value="davinci">Davinci</option>
                                <option value="curie">Curie</option>
                                <option value="babbage">Babbage</option>
                                <option value="ada">Ada</option>
                            </Form.Select> */}
                        </Stack>
                        <Stack className="mt-1">
                            <ProgressBar now={progress} label={progressStatus} animated={progress && progress < 100} />
                        </Stack>
                    </>
                )
            }

            <Alert variant="danger" className="mt-4" style={{ display: `${validationError ? 'block' : 'none'}` }}>
                {validationError}
            </Alert>

            <Form id="ModelForm">
                <h4 className="mt-4">Title *</h4>
                <Form.Group className="mb-2">
                    <Form.Control type="text" placeholder="Enter title" value={modelTitle} onChange={e => setModelTitle(e.target.value)} />
                </Form.Group>
                {!modelExternal ? (
                    <>
                        {modelClass == "1" ?
                            <>
                                <h4 className="mt-2">Classifications *</h4>
                                <Form.Group className="mb-2">
                                    <InputGroup>
                                        <TagsInput
                                            // disabled={!user.is_admin}
                                            // disabled
                                            value={modelClassification}
                                            onChange={(classification) => setModelClassification(classification)}
                                            onlyUnique
                                            addOnBlur
                                            className="form-control"
                                            tagProps={{
                                                className: 'badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2',
                                                classNameRemove: 'btn-close btn-close-white ml-2'
                                            }}
                                            inputProps={{
                                                className: 'react-tagsinput-input',
                                                placeholder: user.is_admin ? 'Add ...' : '',
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </> : ''}

                        <h4 className="mt-2">Task *</h4>
                        <Form.Group className="mb-2">
                            <Form.Control as="textarea" placeholder="Enter task" value={modelTask} onChange={e => setModelTask(e.target.value)} onKeyUp={e => heightAdjust(e.target)} style={{ overflow: 'hidden', height: '100px' }} />
                        </Form.Group>
                        {modelClass == "1" ?
                            <>
                                {/* <h5 className="mt-2">Result Task:</h5> */}
                                {/* <Form.Group className="mb-4">
                                    <p className="px-3"><small>
                                        {`${modelTask} ${modelClassification.length ? (modelTaskSuffix || defaultTaskSuffix) : ''} ${modelClassification.length ? modelClassification.map(c => `"${c}"`).join(', ') + '.' : ''}`}
                                    </small></p>
                                </Form.Group> */}

                                {/* <h5 className="mt-2">Task Suffix (optional)</h5>
                                <Form.Group className="mb-2">
                                    <Form.Control as="textarea" placeholder="Enter task suffix" value={modelTaskSuffix} onChange={e => setModelTaskSuffix(e.target.value)} onKeyUp={e => heightAdjust(e.target)} style={{ overflow: 'hidden', height: '80px' }} />
                                </Form.Group> */}
                            </>
                            : ''}

                        {/* <h5 className="mt-2">Format Phrase (optional)</h5>
                        <Form.Group className="mb-2">
                            <Form.Control as="textarea" placeholder="Enter format phrase" value={modelFormatPhrase} onChange={e => setModelFormatPhrase(e.target.value)} onKeyUp={e => heightAdjust(e.target)} style={{ overflow: 'hidden', height: '80px' }} />
                        </Form.Group> */}
                    </>
                ) : ''}
                {user.is_admin ? (
                    <>
                        <h4 className="mt-4">Users *</h4>
                        <Form.Group className="mb-2">
                            <InputGroup>
                                <TagsInput
                                    renderInput={autocompleteRenderInput}
                                    renderTag={renderCustomerTag}
                                    value={modelUsers}
                                    onChange={values => setModelUsers(values.filter(value => customers.map(customer => customer.id).includes(value)))}
                                    disabled={!user.is_admin}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2',
                                        classNameRemove: 'btn-close ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input',
                                        placeholder: user.is_admin ? 'Add ...' : '',
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                    </>
                ) : ''}
                {!modelGeneral && !modelExternal ? (
                    <>
                        <h4 className="mt-4">Structure *</h4>
                        <Form.Group className="mb-2">
                            <Form.Label>Prompt Fields *</Form.Label>
                            <InputGroup>
                                <TagsInput
                                    disabled={!user.is_admin /* || aiTrainingFileId */}
                                    value={modelPrompt}
                                    onChange={(prompt) => {
                                        if (!retrain) {
                                            const answer = window.confirm('If you make any changes to the structure, the Model will be retrained from the scratch using the entire dataset');
                                            if (answer) {
                                                setModelPrompt(prompt);
                                                setRetrain(true);
                                            }
                                        } else {
                                            setModelPrompt(prompt);
                                        }
                                    }}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2',
                                        classNameRemove: 'btn-close btn-close-white ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input',
                                        placeholder: user.is_admin ? 'Add ...' : '',
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Completion Fields *</Form.Label>
                            <InputGroup>
                                <TagsInput
                                    disabled={!user.is_admin /* || aiTrainingFileId */}
                                    value={modelCompletion}
                                    onChange={(completion) => {
                                        if (!retrain) {
                                            const answer = window.confirm('If you make any changes to the structure, then you will have to Retrain the Model from the scratch using the entire dataset');
                                            if (answer) {
                                                setModelCompletion(completion);
                                                setRetrain(true);
                                            }
                                        } else {
                                            setModelCompletion(completion);
                                        }
                                    }}
                                    onlyUnique
                                    addOnBlur
                                    className="form-control"
                                    tagProps={{
                                        className: 'badge bg-secondary bg-gradient mx-1 my-1 py-2 px-2',
                                        classNameRemove: 'btn-close btn-close-white ml-2'
                                    }}
                                    inputProps={{
                                        className: 'react-tagsinput-input',
                                        placeholder: user.is_admin ? 'Add ...' : '',
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                        {/* <Form.Group className="mb-2">
                            <Form.Label>Prefix (optional)</Form.Label>
                            <InputGroup>
                                <Form.Control type="text" placeholder="Enter the prefix for field names" value={modelPrefix}
                                    onChange={e => {
                                        if (!retrain) {
                                            const answer = window.confirm('If you make any changes to the structure, then you will have to Retrain the Model from the scratch using the entire dataset');
                                            if (answer) {
                                                setRetrain(true);
                                            }
                                        } else {
                                            setModelPrefix(e.target.value);
                                        }
                                    }}
                                />
                            </InputGroup>
                        </Form.Group> */}
                    </>
                ) : ''}

                <div className="d-flex">
                    <Button
                        variant="secondary"
                        className="me-1 my-1"
                        size="sm"
                        onClick={e => {
                            if (pathId) {
                                return history.push(`/paths/path/${pathId}`);
                            } else {
                                return history.push(`/models`);
                            }
                        }}
                    >
                        &lt; Close
                    </Button>
                    {modelId ? (
                        <>
                            <Button
                                variant="danger"
                                className="me-1 my-1 ms-auto"
                                onClick={e => {
                                    handleDelShow();
                                }}
                                size="sm"
                            >
                                Remove
                            </Button>
                            <Modal show={showDel} onHide={handleDelClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Remove the Model</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    You want to delete this Model. This is irreversible. Please type the word "DELETE" in the field to confirm.
                                    <Form.Group className="mb-2">
                                        <Form.Control type="text" placeholder="DELETE" value={modelDelete} onChange={e => setModelDelete(e.target.value)} />
                                    </Form.Group>

                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={e => removeModel(modelId)} disabled={modelDelete != 'DELETE'}>
                                        OK
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    ) : ''}
                    <Button
                        variant="primary"
                        className="me-1 my-1 ms-auto"
                        onClick={e => onSubmit(true, false)}
                        size="sm"
                    >
                        &lt; Save and Close
                    </Button>
                    <Button
                        variant="primary"
                        className="mx-1 my-1"
                        onClick={e => onSubmit(false, false)}
                        size="sm"
                    >
                        Save Changes
                    </Button>
                </div>
                {/* )} */}
            </Form>
            {
                modelDataList && modelId && !modelGeneral && !modelExternal ? (
                    <small>
                        <InfiniteScroll
                            dataLength={modelDataList.length} //This is important field to render the next data
                            next={getMoreItems}
                            hasMore={total > modelDataList.length}
                            // loader={<Spinner accessibilityLabel="..." size="large" />}
                            loader={'...'}
                            endMessage={modelDataList.length ? '' : ''}
                        >
                            <h4 className="mt-4 mb-0">
                                Data
                            </h4>
                            <h6 className="mt-0">
                                <Badge bg="primary" className="me-1 my-1">Total: {total}</Badge>
                                <Badge bg="secondary" className="mx-1 my-1">Applied: {total - pending}</Badge>
                                <Badge bg="success" className="ms-1 my-1">Pending: {pending}</Badge>
                            </h6>

                            <Stack direction="horizontal">
                                <FormControl
                                    id="fileImport"
                                    type="file"
                                    className="me-1 my-1 w-25"
                                    size="sm"
                                    onChange={e => setSelectedFileCsv(e.target.files[0])}
                                    accept=".csv"
                                />
                                <Form.Check
                                    inline
                                    id="replaceData"
                                    type="switch"
                                    className="my-1 ms-3 me-1"
                                    onChange={e => setReplaceData(e.target.checked)}
                                    checked={replaceData}
                                    style={{ fontSize: '1.25rem' }}
                                />
                                <Form.Label htmlFor="replaceData" className="my-1 ms-1 me-3">Replace</Form.Label>
                                <Button className="my-1 mx-1" size="sm" disabled={!selectedFileCsv} onClick={e => onUploadFileCsv(e)}>Import Data</Button>
                                <Button className="ms-auto my-1" size="sm" disabled={!modelDataList.length} onClick={onDownloadFileCsv}>Export Data</Button>
                            </Stack>

                            <Table striped bordered hover size="sm" className="align-middle">
                                <thead>
                                    <tr key="0" className="align-middle">
                                        <th key="plus">+/-</th>
                                        <th key="id"><small>Group ID</small></th>
                                        <th key="status">Status</th>
                                        {modelPrompt.map((prompt, index) =>
                                            <th key={`prompt-${index}`}>"{prompt}"</th>
                                        )}
                                        {modelCompletion.map((completion, index) =>
                                            <th key={`completion-${index}`}>"{completion}"</th>
                                        )}
                                        <th>
                                            <Link to={`/models/model/${modelId}/data`}>
                                                <Button variant="outline-success" size="sm" className="mx-1 my-1" title="Add New Data ...">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </Link>
                                            {/* )} */}
                                        </th>
                                    </tr>
                                </thead>
                                {modelDataList.length ? (
                                    <tbody>
                                        {modelDataList.map((data, index) => {
                                            let prompt = JSON.parse(data.prompt || []);
                                            if (!prompt || !prompt.length) {
                                                prompt = [];
                                            }
                                            let completion = JSON.parse(data.completion || []);
                                            if (!completion || !completion.length) {
                                                completion = [];
                                            }

                                            return (data.id == data.model_data_id) ? (
                                                <tr key={data.id} id={data.id}>
                                                    <td key={`plus-${data.id}`} className="text-center"
                                                        onClick={e => {
                                                            setModelsCollapse({ ...modelsCollapse, [data.model_data_id]: !modelsCollapse[data.model_data_id] });
                                                            setTimeout(document.getElementById(data.id).scrollIntoView(true), 500);
                                                        }}
                                                    >
                                                        {modelsCollapse[data.model_data_id] ?
                                                            <FontAwesomeIcon icon={faAnglesDown} size="lg" title="Collapse variations" /> :
                                                            <FontAwesomeIcon icon={faAnglesRight} size="lg" title="Expand variations" />
                                                        }
                                                        {counts ?
                                                            <Badge pill bg="secondary" className="mx-1 my-1" title="Number of variations">
                                                                {counts[data.model_data_id]}
                                                            </Badge> :
                                                            ''}
                                                    </td>
                                                    <td key={`id-${data.id}`} className="text-center">
                                                        {data.model_data_id}
                                                    </td>
                                                    <td key={`version-${data.id}`}>
                                                        {data.pending ?
                                                            <Badge pill bg="success" className="mx-1 my-1">Pending</Badge> :
                                                            <Badge pill bg="secondary" className="mx-1 my-1">Applied</Badge>}
                                                    </td>
                                                    {modelPrompt.map((v, i) => {
                                                        return <td
                                                            key={`prompt-${data.id}-${i}`}
                                                            className={`${prompt[i] && prompt[i].length ? '' : 'table-danger'}`}
                                                        >
                                                            {prompt[i]}
                                                        </td>;
                                                    })}
                                                    {modelCompletion.map((v, i) => {
                                                        return <td
                                                            key={`completion-${data.id}-${i}`}
                                                            className={`${completion[i] && completion[i].length ? '' : 'table-danger'}`}
                                                        >
                                                            {completion[i]}
                                                        </td>;
                                                    })}
                                                    <td key={`actions-${data.id}`}>
                                                        <Button variant="outline-secondary" size="sm"
                                                            onClick={e => generateVariations(data.id, e)} className="mx-1 my-1"
                                                            title="Generate Variations"
                                                        >
                                                            <FontAwesomeIcon icon={faClone} />
                                                        </Button>
                                                        <Link to={`/models/model/${modelId}/data/${data.id}`}>
                                                            <Button variant="outline-primary" size="sm" className="mx-1 my-1"
                                                                title="Edit Data line"
                                                            >
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </Button>
                                                        </Link>
                                                        <Button variant="outline-danger" size="sm"
                                                            onClick={e => removeModelData(data.id)} className="mx-1 my-1"
                                                            title="Remove Data line"
                                                        >
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </Button>
                                                        {/* )} */}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr key={data.id} style={{
                                                    display: (data.model_data_id && !modelsCollapse[data.model_data_id]) ? 'none' : 'table-row',
                                                }} id={data.id}>
                                                    <td key={`plus-${data.id}`} className={data.model_data_id ? 'text-end' : ''}>
                                                        <span>{data.model_data_id ? '-' : ''}</span>
                                                        {/* <Badge pill bg="secondary" className="mx-1 my-1">{data.id}</Badge> */}
                                                    </td>
                                                    <td key={`id-${data.id}`} className="text-center">
                                                        {data.model_data_id}
                                                    </td>
                                                    <td key={`version-${data.id}`}>
                                                        {data.pending ?
                                                            <Badge pill bg="success" className="mx-1 my-1">Pending</Badge> :
                                                            <Badge pill bg="secondary" className="mx-1 my-1">Applied</Badge>}
                                                    </td>
                                                    {modelPrompt.map((v, i) => {
                                                        return <td
                                                            key={`prompt-${data.id}-${i}`}
                                                            className={`${prompt[i] && prompt[i].length ? '' : 'table-danger'}`}
                                                        >
                                                            {prompt[i]}
                                                        </td>;
                                                    })}
                                                    {modelCompletion.map((v, i) => {
                                                        return <td
                                                            key={`completion-${data.id}-${i}`}
                                                            className={`${completion[i] && completion[i].length ? '' : 'table-danger'}`}
                                                        >
                                                            {completion[i]}
                                                        </td>;
                                                    })}
                                                    <td key={`actions-${data.id}`}>
                                                        <Button variant="outline-secondary" size="sm"
                                                            onClick={e => generateVariations(data.id, e)} className="mx-1 my-1"
                                                            title="Generate Variations"
                                                        >
                                                            <FontAwesomeIcon icon={faClone} />
                                                        </Button>

                                                        <Link to={`/models/model/${modelId}/data/${data.id}`}>
                                                            <Button variant="outline-primary" size="sm" className="mx-1 my-1"
                                                                title="Edit Data line"
                                                            >
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </Button>
                                                        </Link>
                                                        <Button variant="outline-danger" size="sm"
                                                            onClick={e => removeModelData(data.id)} className="mx-1 my-1"
                                                            title="Remove Data line"
                                                        >
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </Button>
                                                        {/* )} */}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (<tbody></tbody>)}
                            </Table>

                            <Modal show={showVariations} onHide={handleVariationsClose} centered size="xl" backdrop="static" keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Approve Generated Variations</Modal.Title>
                                </Modal.Header>
                                <Modal.Body
                                // style={{ overflowY: "auto", maxHeight: "60vh" }}
                                >
                                    <Table striped bordered hover size="sm" className="align-middle">
                                        <thead>
                                            <tr key="0" className="align-middle">
                                                <th key="prompt" width="35%">Prompt</th>
                                                <th key="completion" width="60%">Completion</th>
                                                <th key="id" width="5%" className="text-center"><FontAwesomeIcon icon={faCheck} title="Actions" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {variations && variations.map((variation, index) =>
                                                <tr key={index} className="align-middle">
                                                    <td key={`prompt-${index}`}>
                                                        <small>
                                                            <Badge bg="secondary" className="me-1">Characters: {variation.prompt.length}</Badge>
                                                            <Badge bg="secondary">Words: {variation.prompt.split(' ').length}</Badge>
                                                            <Form.Control as="textarea" size="sm" placeholder="completion"
                                                                rows="4"
                                                                className="no-border"
                                                                value={variation.prompt}
                                                                onChange={e => {
                                                                    const _variations = Object.assign([], variations);
                                                                    _variations[index] = { ...variation, prompt: e.target.value };
                                                                    setVariations(_variations);
                                                                }}
                                                            />
                                                        </small>
                                                    </td>
                                                    <td key={`completion-${index}`}>
                                                        <small>
                                                            <Badge bg="secondary" className="me-1">Characters: {variation.completion.length}</Badge>
                                                            <Badge bg="secondary">Words: {variation.completion.split(' ').length}</Badge>
                                                            <Form.Control as="textarea" size="sm" placeholder="completion"
                                                                rows="4"
                                                                className="no-border"
                                                                value={variation.completion}
                                                                onChange={e => {
                                                                    const _variations = Object.assign([], variations);
                                                                    _variations[index] = { ...variation, completion: e.target.value };
                                                                    setVariations(_variations);
                                                                }}
                                                            />
                                                        </small>
                                                    </td>
                                                    <td key={index} className="text-center">
                                                        <Button
                                                            title="Remove"
                                                            variant="outline-danger" size="sm"
                                                            onClick={e => {
                                                                const _variations = Object.assign([], variations);
                                                                _variations.splice(index, 1);
                                                                setVariations(_variations);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon size="sm" icon={faTrashCan} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="outline-success"
                                        onClick={async e => {
                                            saveVariations(variations[0].model_data_id);
                                        }}
                                    >
                                        Approve and add as Model Training Data
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </InfiniteScroll>
                    </small>
                ) : ''
            }
        </>);
}
