import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, Badge, Breadcrumb, Button, Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
// import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';
import { modelsUrl } from '../../utils/api';
import { heightAdjust, jsonString } from '../../utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { gpt3Encode, gpt3Decode } from '../GPT3/Encoder';

async function getModel(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function playModel(token, modelId, prompt, modelVersion) {

    return fetch(`${modelsUrl}/${modelId}/play`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            prompt: prompt,
            model_version_id: modelVersion,
        }),
    })
        .then(data => data.json());
}

async function playTextToSpeech(token, modelId, text, voiceId) {

    return fetch(`${modelsUrl}/${modelId}/speech`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            text: text,
            voiceId: voiceId,
        }),
    })
        .then(data => data.json());
}

async function createModelData(token, modelId, data) {

    return fetch(`${modelsUrl}/${modelId}/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function updateModelData(token, modelId, data, modelDataId) {

    return fetch(`${modelsUrl}/${modelId}/data/${modelDataId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then(data => data.json());
}

async function getModelHistoryList(token, modelId, versionId) {

    return fetch(`${modelsUrl}/${modelId}/versions/${versionId}/history`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getVoices(token, modelId) {

    return fetch(`${modelsUrl}/${modelId}/voices`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function getHistory(token, modelId, versionId, historyId) {

    return fetch(`${modelsUrl}/${modelId}/versions/${versionId}/history/${historyId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    })
        .then(data => data.json());
}

async function playModelExternal(token, modelId, formData, from = 'en-US', to = 'es-US') {

    return fetch(`${modelsUrl}/${modelId}/external?from=${from}&to=${to}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: formData,
    })
        .then(data => data.json());
}

export default function ModelPlay(props) {
    let {
        modelId: id,
        // modelVersionId,
    } = useParams();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    const [modelVersions, setModelVersions] = useState();
    const [modelVersion, setModelVersion] = useState();
    const [modelId, setModelId] = useState(id);
    const [modelGeneral, setModelGeneral] = useState();
    const [modelExternal, setModelExternal] = useState();
    const [modelApi, setModelApi] = useState();
    const [modelTitle, setModelTitle] = useState('');
    const [modelPrompt, setModelPrompt] = useState([]);
    const [modelCompletion, setModelCompletion] = useState([]);
    const [voices, setVoices] = useState();
    const [voice, setVoice] = useState();
    const [audioUrl, setAudioUrl] = useState();
    const { settings, setSettings } = useSettings();
    const [error, setError] = useState(null);

    const [prompts, setPrompts] = useState([]);
    const [completions, setCompletions] = useState([]);

    const [prompt, setPrompt] = useState('');
    const [modelHistoryList, setModelHistoryList] = useState([]);
    const [historyId, setHistoryId] = useState(null);
    const [history, setHistory] = useState({});

    const [modelDataPrompt, setModelDataPrompt] = useState([]);
    const [modelDataCompletion, setModelDataCompletion] = useState([]);

    const [selectedFileExternal, setSelectedFileExternal] = useState(null);
    const [langFrom, setLangFrom] = useState('en-US');
    const [langTo, setLangTo] = useState('es');

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (id) {
            setModelId(id);
            // const query = new URLSearchParams(location.search);
            // setModelVersion(query.get('version') || null);
            loadModel();
        }

        return () => {
            setModelVersions([]);
            setModelVersion(null);
            setModelGeneral();
            setModelExternal();
            setModelApi();
            setVoices([]);
            setModelTitle('');
            setModelPrompt([]);
            setModelCompletion([]);
            setModelDataPrompt([]);
            setModelDataCompletion([]);
            // setModelDataEnabled(false);
            // setModelDataSaved(false);
            setModelHistoryList([]);
        }
    }, [token]);

    // useEffect(() => {
    //     if (modelVersion) {
    //         loadModelHistoryList();
    //     }
    // }, [modelVersion]);

    const loadModel = async () => {
        setError();
        await getModel(token, modelId, modelVersion).then(async r => {
            if (!r.model || !r.model.id) {
                console.log(r);
                setError(r);
            }
            setModelVersions(r.model.versions);
            setModelVersion(r.model.versions.filter(version => version.ai_fine_tune_status == 'succeeded')[0].id);
            setModelGeneral(r.model.general);
            setModelExternal(r.model.external);
            setModelApi(r.model.api);
            // if (r.model.api == 'elv') {
            await getVoices(token, modelId).then(r => {
                if (!r.voices) {
                    console.log(r);
                    setError(r);
                }
                setVoices(r.voices);
            });
            // }
            setModelTitle(r.model.title);
            setModelPrompt(JSON.parse(r.model.prompt || []));
            setModelCompletion(JSON.parse(r.model.completion || []));
            // setAiTrainingModelName(r.model.ai_fine_tuned_model || null);
            // setAiTrainingModelStatus(r.model.ai_fine_tune_status || '');
        });

    };

    const loadModelHistoryList = () => {
        getModelHistoryList(token, modelId, modelVersion).then(r => {
            if (!r.list) {
                console.log(r);
                setError(r);
            }
            setModelHistoryList(r.list || []);
            if (r.list && r.list[0].id) {
                getHistoryData(r.list[0].id);
            }
        });
    };

    const textToSpeech = () => {
        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;

        const goProgess = document.getElementById('goProgress');
        goProgess.style.visibility = 'visible';

        playTextToSpeech(token, modelId, prompt, voice).then(r => {
            if (!r.received) {
                console.log(r);
                setError(r);
            }
            setPrompt('');
            goProgess.style.visibility = 'hidden';
            goButton.disabled = false;
            loadModelHistoryList();
        });
    };

    const quickTextToSpeech = async (text) => {
        setError();
        const speakButton = document.getElementById('speakButton');
        speakButton.disabled = true;

        const goProgess = document.getElementById('goProgress');
        goProgess.style.visibility = 'visible';

        const jsonText = jsonString(text);

        playTextToSpeech(token, '37', jsonText ? JSON.stringify(Object.values(jsonText)[0]) : text, voice).then(async r => {
            if (!r.received) {
                console.log(r);
                setError(r);
            }
            // setPrompt('');
            const received = JSON.parse(r.received);
            setAudioUrl(received.url);
            goProgess.style.visibility = 'hidden';
            speakButton.disabled = false;
            loadModelHistoryList();
        });
    };

    const getCompletion = () => {
        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;
        playModel(token, modelId, prompt, modelVersion).then(r => {
            if (typeof r.completion === 'undefined') {
                console.log(r);
                setError(r);
            }
            setPrompt('');
            goButton.disabled = false;
            loadModelHistoryList();
        });
    };

    const getHistoryData = (historyId) => {
        getHistory(token, modelId, modelVersion, historyId).then(r => {
            if (!r.history) {
                console.log(r);
                setError(r);
            }
            setHistory(r.history || []);
            setModelDataPrompt(r.history.parsed_prompt);
            setModelDataCompletion(r.history.parsed_completion);
            setHistoryId(historyId);
        });
    };

    const onSubmit = async () => {
        setError();
        const data = {
            prompt: JSON.stringify(modelDataPrompt),
            completion: JSON.stringify(modelDataCompletion),
        };
        await createModelData(token, modelId, data).then(r => {
            if (!r.modelDataId) {
                console.log(r);
                setError(r);
            }
            setShowPopup(true);
            // setHistoryId();
        });
    };

    const onQuickAdd = async (historyId) => {
        setError();
        getHistory(token, modelId, modelVersion, historyId).then(r => {
            if (!r.history) {
                console.log(r);
                setError(r);
            }
            if (!r.history.parsed_prompt[0] || !r.history.parsed_completion[0]) {
                return false;
            }
            const data = {
                prompt: JSON.stringify(r.history.parsed_prompt),
                completion: JSON.stringify(r.history.parsed_completion),
            };
            createModelData(token, modelId, data).then(r => {
                if (!r.modelDataId) {
                    console.log(r);
                    setError(r);
                }
                setHistoryId();
            });
        });
    };

    const goExternal = async (e) => {
        setError();
        const goButton = document.getElementById('goButton');
        goButton.disabled = true;

        const goProgess = document.getElementById('goProgress');
        goProgess.style.visibility = 'visible';

        const fileInput = document.getElementById('fileUpload');
        const formData = new FormData();

        e.target.disabled = true;

        formData.append("uploadFileExternal", selectedFileExternal, selectedFileExternal.name);

        await playModelExternal(token, modelId, formData, langFrom, langTo).then(r => {
            fileInput.value = null;
            if (typeof r.received === 'undefined') {
                console.log(r);
                setError(r);
            }
            setPrompt('');
            goProgess.style.visibility = 'hidden';
            goButton.disabled = false;
            loadModelHistoryList();
        });
    };

    const prepareDecode = (encoded) => {
        const result = [];
        for (let token of encoded) {
            // console.log({ token, string: gpt3Decode([token]) });
            result.push({ token, string: gpt3Decode([token]) });
        }
        return result;
    }

    const modelPageTitle = `Model "${modelTitle}"`;

    const languagesFrom = [
        { title: 'Afrikaans', code: 'af-ZA' },
        { title: 'Arabic, Gulf', code: 'ar-AE' },
        { title: 'Arabic, Modern Standard', code: 'ar-SA' },
        { title: 'Chinese, Simplified', code: 'zh-CN' },
        { title: 'Chinese, Traditional', code: 'zh-TW' },
        { title: 'Danish', code: 'da-DK' },
        { title: 'Dutch', code: 'nl-NL' },
        { title: 'English, Australian', code: 'en-AU' },
        { title: 'English, British', code: 'en-GB' },
        { title: 'English, Indian', code: 'en-IN' },
        { title: 'English, Irish', code: 'en-IE' },
        { title: 'English, New Zealand', code: 'en-NZ' },
        { title: 'English, Scottish', code: 'en-AB' },
        { title: 'English, South African', code: 'en-ZA' },
        { title: 'English, US', code: 'en-US' },
        { title: 'English, Welsh', code: 'en-WL' },
        { title: 'French', code: 'fr-FR' },
        { title: 'French, Canadian', code: 'fr-CA' },
        { title: 'Farsi', code: 'fa-IR' },
        { title: 'German', code: 'de-DE' },
        { title: 'German, Swiss', code: 'de-CH' },
        { title: 'Hebrew', code: 'he-IL' },
        { title: 'Hindi, Indian', code: 'hi-IN' },
        { title: 'Indonesian', code: 'id-ID' },
        { title: 'Italian', code: 'it-IT' },
        { title: 'Japanese', code: 'ja-JP' },
        { title: 'Korean', code: 'ko-KR' },
        { title: 'Malay', code: 'ms-MY' },
        { title: 'Portuguese', code: 'pt-PT' },
        { title: 'Portuguese, Brazilian', code: 'pt-BR' },
        { title: 'Russian', code: 'ru-RU' },
        { title: 'Spanish', code: 'es-ES' },
        { title: 'Spanish, US', code: 'es-US' },
        { title: 'Tamil', code: 'ta-IN' },
        { title: 'Telugu', code: 'te-IN' },
        { title: 'Thai', code: 'th-TH' },
        { title: 'Turkish', code: 'tr-TR' },
    ];

    const languagesTo = [
        { title: 'Afrikaans', code: 'af' },
        { title: 'Albanian', code: 'sq' },
        { title: 'Amharic', code: 'am' },
        { title: 'Arabic', code: 'ar' },
        { title: 'Armenian', code: 'hy' },
        { title: 'Azerbaijani', code: 'az' },
        { title: 'Bengali', code: 'bn' },
        { title: 'Bosnian', code: 'bs' },
        { title: 'Bulgarian', code: 'bg' },
        { title: 'Catalan', code: 'ca' },
        { title: 'Chinese (Simplified)', code: 'zh' },
        { title: 'Chinese (Traditional)', code: 'zh-TW' },
        { title: 'Croatian', code: 'hr' },
        { title: 'Czech', code: 'cs' },
        { title: 'Danish', code: 'da' },
        { title: 'Dari', code: 'fa-AF' },
        { title: 'Dutch', code: 'nl' },
        { title: 'English', code: 'en' },
        { title: 'Estonian', code: 'et' },
        { title: 'Farsi (Persian)', code: 'fa' },
        { title: 'Filipino, Tagalog', code: 'tl' },
        { title: 'Finnish', code: 'fi' },
        { title: 'French', code: 'fr' },
        { title: 'French (Canada)', code: 'fr-CA' },
        { title: 'Georgian', code: 'ka' },
        { title: 'German', code: 'de' },
        { title: 'Greek', code: 'el' },
        { title: 'Gujarati', code: 'gu' },
        { title: 'Haitian Creole', code: 'ht' },
        { title: 'Hausa', code: 'ha' },
        { title: 'Hebrew', code: 'he' },
        { title: 'Hindi', code: 'hi' },
        { title: 'Hungarian', code: 'hu' },
        { title: 'Icelandic', code: 'is' },
        { title: 'Indonesian', code: 'id' },
        { title: 'Irish', code: 'ga' },
        { title: 'Italian', code: 'it' },
        { title: 'Japanese', code: 'ja' },
        { title: 'Kannada', code: 'kn' },
        { title: 'Kazakh', code: 'kk' },
        { title: 'Korean', code: 'ko' },
        { title: 'Latvian', code: 'lv' },
        { title: 'Lithuanian', code: 'lt' },
        { title: 'Macedonian', code: 'mk' },
        { title: 'Malay', code: 'ms' },
        { title: 'Malayalam', code: 'ml' },
        { title: 'Maltese', code: 'mt' },
        { title: 'Marathi', code: 'mr' },
        { title: 'Mongolian', code: 'mn' },
        { title: 'Norwegian', code: 'no' },
        { title: 'Pashto', code: 'ps' },
        { title: 'Polish', code: 'pl' },
        { title: 'Portuguese (Brazil)', code: 'pt' },
        { title: 'Portuguese (Portugal)', code: 'pt-PT' },
        { title: 'Punjabi', code: 'pa' },
        { title: 'Romanian', code: 'ro' },
        { title: 'Russian', code: 'ru' },
        { title: 'Serbian', code: 'sr' },
        { title: 'Sinhala', code: 'si' },
        { title: 'Slovak', code: 'sk' },
        { title: 'Slovenian', code: 'sl' },
        { title: 'Somali', code: 'so' },
        { title: 'Spanish', code: 'es' },
        { title: 'Spanish (Mexico)', code: 'es-MX' },
        { title: 'Swahili', code: 'sw' },
        { title: 'Swedish', code: 'sv' },
        { title: 'Tamil', code: 'ta' },
        { title: 'Telugu', code: 'te' },
        { title: 'Thai', code: 'th' },
        { title: 'Turkish', code: 'tr' },
        { title: 'Ukrainian', code: 'uk' },
        { title: 'Urdu', code: 'ur' },
        { title: 'Uzbek', code: 'uz' },
        { title: 'Vietnamese', code: 'vi' },
        { title: 'Welsh', code: 'cy' },
    ];

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item href="#/models">Models</Breadcrumb.Item>
                <Breadcrumb.Item href={`#/models/model/${modelId}`}>{modelPageTitle}</Breadcrumb.Item>
                <Breadcrumb.Item active>Sandbox</Breadcrumb.Item>
                {/* <a
                    href="#/login"
                    className="ms-auto"
                    onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}
                >Log out</a> */}
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {
                        `${error.error ?
                            (
                                (error.error.msg || '') + ' ' +
                                (error.error.code ? ('(' + error.error.code + ')') : '')
                            ) : (error)
                        }`
                    }
                </Alert>
            )}

            <h2 className="my-2 text-center" key="pageTitle">
                {modelPageTitle}
            </h2>

            <Modal show={showPopup} onHide={e => {
                setShowPopup(false);
                setHistoryId();
            }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Data Added</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The data has been successfully added to the current Model
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => {
                        setShowPopup(false);
                        setHistoryId();
                    }}>
                        OK, go ahead
                    </Button>
                </Modal.Footer>
            </Modal>

            {!modelGeneral && !modelExternal ? (
                <Form.Select
                    className="my-3"
                    value={modelVersion}
                    onChange={e => setModelVersion(e.target.value)}
                >
                    {modelVersions ? modelVersions
                        // .filter(version => version.ai_fine_tune_status == 'succeeded')
                        .map(version => (
                            <option disabled={version.ai_fine_tune_status != 'succeeded'} value={version.id}>Model Version {new Date(version.created).toLocaleString("en-US")}</option>
                        )) : ''}
                </Form.Select>
            ) : ''}

            {modelId && modelHistoryList ? (
                modelExternal ?
                    <>
                        {modelApi == 'cft' ? <>
                            <Alert variant="primary">
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Label
                                                className="mx-1"
                                            >
                                                Source Video or Audio:
                                            </Form.Label>
                                            <Form.Control
                                                id="fileUpload"
                                                type="file"
                                                className="my-1"
                                                onChange={e => setSelectedFileExternal(e.target.files[0])}
                                                accept=".mp3, .mp4, .wav, .flac, .amr, .ogg, .webm"
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label
                                                className="mx-1"
                                            >
                                                From:
                                            </Form.Label>
                                            <Form.Select
                                                className="my-1"
                                                value={langFrom}
                                                onChange={e => setLangFrom(e.target.value)}
                                            >
                                                {languagesFrom.map(lang => (
                                                    <option value={lang.code}>{lang.title}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Label
                                                className="mx-1"
                                            >
                                                To:
                                            </Form.Label>
                                            <Form.Select
                                                className="my-1"
                                                value={langTo}
                                                onChange={e => setLangTo(e.target.value)}
                                            >
                                                {languagesTo.map(lang => (
                                                    <option value={lang.code}>{lang.title}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ProgressBar animated style={{ visibility: 'hidden' }} now={100} label="processing" id="goProgress" className="mt-2 mb-1" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button id='goButton' className="mx-auto my-2 w-100" type="button" disabled={!selectedFileExternal} onClick={(e) => {
                                                goExternal(e);
                                            }}>Go</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Alert>

                            {modelHistoryList.map(pair => {
                                return pair && pair.sent && pair.sent.url ? (
                                    <Alert key={`pair-${pair.id}`} className="mx-0 my-2" variant="secondary">
                                        <div className="">
                                            <video controls preload="metadata" style={{
                                                background: 'radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(127,127,127,0) 100%)',
                                                display: 'block',
                                                margin: '0 auto',
                                                maxWidth: '100%',
                                                maxHeight: '100vh',
                                            }}>
                                                <source src={pair.sent.url} />
                                                <track label={pair.received ? pair.received.TargetLanguageCode : ''} kind="subtitles" srcLang={pair.received ? pair.received.TargetLanguageCode : ''} src={`data:text/vtt;base64,${pair.vttData}`} default />
                                            </video>
                                        </div>
                                        <hr></hr>
                                        <div className="d-flex">
                                            <Form.Label
                                                className="col-form-label ms-1 me-auto my-1"
                                            >
                                                <small>{pair.created}</small>
                                            </Form.Label>
                                            <Button variant="outline-success" className="ms-auto me-1 my-1">
                                                <a target="_blank" href={`data:text/vtt;base64,${pair.vttData}`} download={pair.received && pair.received.vtt}>Download Captioning File (VTT)</a>
                                            </Button>
                                            <Button variant="outline-success" className="ms-1 my-1">
                                                <a target="_blank" href={`${pair.sent.url}`} download>Download Video</a>
                                            </Button>
                                        </div>
                                    </Alert>
                                ) : '';
                            })}
                        </> : ''}
                        {modelApi == 'elv' && voices && voices.length ? <>
                            <Alert key={`pair-new`} variant="primary">
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Select
                                                className="my-1"
                                                value={voice}
                                                onChange={e => setVoice(e.target.value)}
                                            >
                                                <option value={0} disabled selected>Select the voice:</option>
                                                {voices.map(voice => (
                                                    <option value={voice.voice_id}>{voice.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Enter prompt"
                                                className="mb-2"
                                                value={prompt}
                                                onChange={(e) => setPrompt(e.target.value)}
                                                onKeyUp={e => heightAdjust(e.target)}
                                                style={{ overflow: 'hidden', height: '100px' }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ProgressBar animated style={{ visibility: 'hidden' }} now={100} label="processing" id="goProgress" className="mt-2 mb-1" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button id='goButton' type="button" onClick={(e) => {
                                                textToSpeech();
                                            }}>Go</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Alert>

                            {modelHistoryList.map(pair => {
                                return pair && pair.received ? (
                                    <Alert key={`pair-${pair.id}`} className="mx-0 my-2" variant="secondary">
                                        <Row>
                                            <Col>
                                                <audio controls preload="metadata" className="w-100">
                                                    <source src={pair.received.url} />
                                                </audio>
                                            </Col>
                                            <Col>
                                                <Form.Label
                                                    className="col-form-label ms-1 me-auto my-1"
                                                >
                                                    <small>{pair.sent.prompt}</small>
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <div className="d-flex">
                                            <Form.Label
                                                className="col-form-label ms-1 me-auto my-1"
                                            >
                                                <small>{pair.created}</small>
                                            </Form.Label>
                                            <Button variant="outline-success" className="ms-1 my-1">
                                                <a target="_blank" href={`${pair.received.url}`} download>Download Audio</a>
                                            </Button>
                                        </div>
                                    </Alert>
                                ) : '';
                            })}
                        </> : ''}
                    </>
                    : (
                        <>
                            {!historyId ?
                                (
                                    <Alert key={`pair-new`} variant="primary">
                                        <Form>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Enter prompt"
                                                className="mb-2"
                                                value={prompt}
                                                onChange={(e) => setPrompt(e.target.value)}
                                                onKeyUp={e => heightAdjust(e.target)}
                                                style={{ overflow: 'hidden', height: '100px' }}
                                            />
                                            <Button id='goButton' type="button" onClick={(e) => {
                                                getCompletion();
                                            }}>Go</Button>
                                        </Form>
                                    </Alert>
                                ) : ''}
                            <>
                                {modelHistoryList.map(pair =>
                                (<>
                                    {historyId == pair.id ?
                                        (
                                            <Alert key={`pair-${pair.id}`} variant="primary">
                                                <Form>
                                                    <h2 className="my-2 text-center">{history.model_data_id ? `Edit Data #${history.model_data_id}` : 'Response'}</h2>
                                                    <fieldset>
                                                        <legend>Prompt</legend>
                                                        {modelPrompt.map((prompt, index) =>
                                                            <Row className="align-items-center" key={`prompt-${index}`}>
                                                                <Col className="text-end" sm="2">
                                                                    <Form.Label>{prompt} *</Form.Label>
                                                                </Col>
                                                                <Col sm="10">
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        placeholder={`Enter ${prompt}`}
                                                                        value={modelDataPrompt[index]}
                                                                        onChange={e => setModelDataPrompt(modelPrompt.map((v, i) => i == index ? e.target.value : modelDataPrompt[i]))}
                                                                        className="mb-2"
                                                                        onKeyUp={e => heightAdjust(e.target)}
                                                                        style={{ overflow: 'hidden', height: '100px' }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Completion</legend>
                                                        {modelCompletion.map((completion, index) =>
                                                            <Row className="align-items-center" key={`completion-${index}`}>
                                                                <Col className="text-end" sm="2">
                                                                    <Form.Label>{completion} *</Form.Label>
                                                                </Col>
                                                                <Col sm="10">
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        placeholder={`Enter ${completion}`}
                                                                        value={modelDataCompletion[index]}
                                                                        onChange={e => setModelDataCompletion(modelCompletion.map((v, i) => i == index ? e.target.value : modelDataCompletion[i]))}
                                                                        className="mb-2"
                                                                        onKeyUp={e => heightAdjust(e.target)}
                                                                        style={{ overflow: 'hidden', height: '80px' }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Technical details</legend>
                                                        <Row className="" key="sent">
                                                            <Col className="text-end" sm="2">
                                                                <Form.Label>Sent &nbsp;&nbsp;</Form.Label>
                                                            </Col>
                                                            <Col sm="10">
                                                                <small>
                                                                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                                                                        {history.prompt}
                                                                    </pre>
                                                                </small>
                                                            </Col>
                                                        </Row>
                                                        <Row className="" key="received">
                                                            <Col className="text-end" sm="2">
                                                                <Form.Label>Received &nbsp;&nbsp;</Form.Label>
                                                            </Col>
                                                            <Col sm="10">
                                                                <small>
                                                                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                                                                        {history.completion}
                                                                    </pre>
                                                                </small>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                    <Row>
                                                        <Col>
                                                            <ProgressBar animated style={{ visibility: 'hidden' }} now={100} label="processing" id="goProgress" className="mt-2 mb-1" />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {voices && voices.length ?
                                                                <Form.Select
                                                                    disabled={audioUrl}
                                                                    className="mx-1 my-1"
                                                                    size="sm"
                                                                    value={voice}
                                                                    onChange={e => setVoice(e.target.value)}
                                                                >
                                                                    <option value={0} disabled selected>Select the voice:</option>
                                                                    {voices.map(voice => (
                                                                        <option value={voice.voice_id} selected={voice.voice_id == 'jCX36VgfjXWPYoQu6vrB'}>{voice.name}</option>
                                                                    ))}
                                                                </Form.Select> : ''}
                                                        </Col>
                                                        <Col>
                                                            {audioUrl ?
                                                                <audio controls preload="metadata" className="mx-1">
                                                                    <source src={audioUrl} />
                                                                </audio> :
                                                                <Button id='speakButton' type="button"
                                                                    className="mx-1 my-1"
                                                                    size="sm"
                                                                    onClick={(e) => {
                                                                        quickTextToSpeech(history.completion);
                                                                    }}>Generate speech</Button>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                variant="success"
                                                                className="ms-auto my-1"
                                                                onClick={onSubmit}
                                                                size="sm"
                                                            >
                                                                Save as Model Data
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                variant="secondary"
                                                                className="mx-auto my-1"
                                                                size="sm"
                                                                onClick={
                                                                    (e) => {
                                                                        // setHistoryId(null);
                                                                        // setHistory(null);
                                                                        return window.location.reload();
                                                                    }
                                                                }
                                                            >
                                                                Close
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Alert>
                                        ) : ''
                                    }
                                </>
                                ))}
                            </>
                        </>
                    )) : ''
            }
        </>);
}