import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Col, Container, Row, Table } from 'react-bootstrap';

import useToken from '../App/useToken';
import useUser from '../App/useUser';
import useSettings from '../App/useSettings';

import { dashboardUrl } from '../../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMoneyBillAlt, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faPlug, faLayerGroup, faBrain, faBuilding, faUsers, faFolderTree } from '@fortawesome/free-solid-svg-icons';

// async function getDashboard(token) {

//     return fetch(dashboardUrl, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//         },
//     })
//         .then(data => data.json());
// }

export default function Dashboard(props) {
    const history = useHistory();
    const location = useLocation();
    const { token, setToken } = useToken();
    const { user, setUser } = useUser();
    // const [dashboard, setDashboard] = useState({});
    const { settings, setSettings } = useSettings();
    const [error, setError] = useState(null);

    // useEffect(() => {
    //     loadDashboard(token);

    //     return () => setDashboard({});
    // }, [user, token]);

    // const loadDashboard = (token) => {
    //     setError();
    //     getDashboard(token).then(r => {
    //         if (!r.dashboard) {
    //             console.log(r);
    //             setError(r);
    //         }
    //         setDashboard(r.dashboard || {});
    //     });
    // }

    return (
        <>
            <Breadcrumb>
                {/* <Breadcrumb.Item href="#/" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('linkUser').click();
                }}>Home</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            {error && (
                <Alert variant="danger" className="row">
                    {`${error.error &&
                        (
                            (error.error.msg || '') + ' ' +
                            (error.error.code ? ('(' + error.error.code + ')') : '')
                        )}`}
                </Alert>
            )}
            <h2 className="my-4 text-center">Dashboard</h2>
            {/* {dashboard ? (
                <></>
            ) : ''} */}
            <Row>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/paths">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faFolderTree} size='4x' /></p>
                            <p>Paths</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/flows">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faLayerGroup} size='4x' /></p>
                            <p>Flows</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/models">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faBrain} size='4x' /></p>
                            <p>Models</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/companies">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faBuilding} size='4x' /></p>
                            <p>Companies</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/users">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faUsers} size='4x' /></p>
                            <p>Users</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/billing">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faMoneyBillAlt} size='4x' /></p>
                            <p>Billing</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/api">
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faPlug} size='4x' /></p>
                            <p>API</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#/account" onClick={() => window.parent.postMessage('sAccount', 'https://strietzel.ai')}>
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faUser} size='4x' /></p>
                            <p>My Account</p>
                        </Button>
                    </a>
                </Col>
                <Col className="col-6 col-md-4 col-sm-6 py-3 px-3">
                    <a className="w-100" style={{ aspectRatio: '1' }} href="#" onClick={e => {
                        setToken({ token: null });
                        setUser({});
                        const linkLogout = document.getElementById('linkLogout');
                        if (linkLogout) linkLogout.click();
                    }}>
                        <Button variant="outline-success" className="w-100" style={{ aspectRatio: '1' }}>
                            <p><FontAwesomeIcon icon={faArrowRightFromBracket} size='4x' /></p>
                            <p>Log out</p>
                        </Button>
                    </a>
                </Col>
            </Row>
        </>);
}