const isLocal = window.location.origin.includes('.local') || window.location.origin.includes('localhost');

// export const brandUrl = 'http://strietzel.local:3000/';
// export const brandUrl = 'http://app.strietzel.ai/';

export const apiUrl =  'https://api.strietzel.ai/v1/';

// export const loginUrl = apiUrl + 'users/login';
export const loginUrl = apiUrl + 'users/shopify_login';
// export const registerUrl = apiUrl + 'users/register';
export const registerUrl = apiUrl + 'users/shopify_register';
export const modelsUrl = apiUrl + 'models';
export const pathsUrl = apiUrl + 'paths';
export const flowsUrl = apiUrl + 'flows';
export const usersUrl = apiUrl + 'users';
export const dashboardUrl = apiUrl + 'dashboard';
export const apiKeysUrl = apiUrl + 'users/me/api_key';
export const balanceUrl = apiUrl + 'users/me/balance';
export const statUrl = apiUrl + 'users/me/statistics';
export const docUrl = 'https://api.strietzel.ai/data/doc/doc.html';
export const companiesUrl = apiUrl + 'companies';


// Useful functions library
export function removeParams(sParam) {
    let url = window.location.href.split('?')[0] + '?';
    let sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParamName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParamName = sURLVariables[i].split('=');
        if (sParamName[0] != sParam) {
            url = url + sParamName[0] + '=' + sParamName[1] + '&'
        }
    }

    return url.substring(0, url.length - 1);
}